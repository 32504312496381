.stockist-list {
	padding: 10px 0 30px;
	margin: 0;
	text-align: center;
	font-size: 0;
	
	@media @vp-max-small {
		padding-bottom: 20px;
	}
	
	> .stockist {
		list-style: none;
		display: inline-block;
		margin: 7px;
		height: 50px;
		
		@media @vp-max-grid-narrow {
			height: 40px;
			margin: 5px;
		}
		
		> .link {
			display: block;
			background: white;
			border-radius: 5px;
			position: relative;
			transition: all 0.2s ease;
			top: 0;
			height: 100%;
			
			&:hover {
				top: -2px;
				box-shadow: 0 2px 2px darken(@yellow, 20%);
			}
		}
		
		img {
			display: block;
			max-height: 100%;
			width: auto;
		}
	}
}