.news-section {
  h1 {
    margin-bottom: 0;
  }
}

article footer .news-tags {
  float: right;
  
  @media @vp-max-tablet {
    float: left;
  }
}