.photo-block {
	margin: 1em 0;
	
	> .image {
		position: relative;
		background: @lightest-grey;
		
		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	
	&.-lazy {
		> .image img {
			opacity: 0;
			transition: opacity 0.5s ease-out;
			
			
			&.lazyloaded { opacity: 1; }
		}
	}
	
	.caption {
		.metadata-text();
		line-height: 24 / 16;
		margin-top: 0.5em;
		text-align: right;
		
		@media @vp-tablet-wide {
			font-size: @fs-mobile-detail;
		}
		
		@media @vp-max-small {
			font-size: 14px;
		}
		
		p { margin: 0; }
	}
}