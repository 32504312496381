article.press {
  .cf();
}

.press-section {
  width: 50%;
  float: left;
  
  @media @vp-max-tablet {
    width: 100%;
  }
  
  h4 {
    text-align: left;
    color: @deep-grey;
    padding: @fs 0 (@fs * 0.7);
    font-size: 90%;
    font-family: @font-serif;
    font-weight: bold;
  }
  
  ul {
    margin-top: 0;
    list-style-type: none;
    padding: 0;
    // font-family: @font-sans;
    
    a {
      box-shadow: none;
      
      &::after{
        content: "";
        font-family: @font-sans;
        color: @white;
        transform: none;
        display: inline-block;
		width: 14px;
		height: 14px;
		background-image: url("../img/link-arrow.svg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
        position: relative;
		opacity: 0;
        .transition(150ms all ease);
		margin-left: 0.25em;
      }
      
      &:hover::after {
        opacity: 1;
      }

    }
  }
}