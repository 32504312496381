.book-screen {
	.container {
		max-width: unit(@grid-wide-width, px);
		
		nav {
			margin: 0 auto;
		}
	}
	
	header {
		padding-left: @fs;
		padding-right: @fs;
		
		.container {
			padding: 0;
			
			nav {
				padding-left: percentage(1 / @grid-wide-cols);
				padding-right: 4px;
				
				@media @vp-max-tablet-wide {
					padding-left: 0;
				}
			}
		}
		
		&.compressed {
			
		}
	}
	
	.header {
		position: relative;
		margin-bottom: 2em;
		
		&:before {
			content: "";
			display: block;
			position: absolute;
			z-index: 0;
			background: @yellow;
			top: -20px;
			bottom: 0;
			right: -500%;
			left: -500%;
		}
		
		> * {
			position: relative;
			z-index: 1;
		}
		
		.tagline {
			&.-large {
				@media @vp-min-grid-wide {
					font-size: 20px;
					line-height: 30 / 20;
					padding-bottom: 1.5em;
				}
			}
		}
	}
	
	main {
		padding-left: @fs;
		padding-right: @fs;
		overflow: hidden;
		
		@media @vp-max-tablet {
			padding-top: 10px;
		}
		
		.container {
			max-width: unit(@grid-wide-width, px);
			padding: 0;
		}
	}
	
	.book-quote {
		&.-featured {
			@media @vp-max-tablet {
				margin-bottom: 3em;
			}
		}
	}
	
	.book-content {
		display: flex;
		margin-bottom: 2em;
		
		@media @vp-max-tablet {
			flex-wrap: wrap;
		}
	}
	
	aside {
		width: percentage( 6 / @grid-wide-cols );
		order: 2;
		
		@media @vp-max-tablet {
			width: 100%;
		}
	}
	
	.book-body {
		width: percentage(8 / @grid-wide-cols);
		margin-left: percentage(1 / @grid-wide-cols);
		margin-right: percentage(1 / @grid-wide-cols);
		order: 1;
		
		@media @vp-max-tablet {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
		
		.logo-link {
			width: percentage(6 / 8);
			display: block;
			margin: 0 auto;
			#gutters > .as-padding(8);
			padding-top: 20px;
			padding-bottom: 20px;
			
			@media @vp-min-tablet {
				width: 70%;
			}
		}
		
		.body-text {
			@media @vp-min-tablet {
				#gutters > .as-padding(8);
			}
		}
	}
	
	footer.global {
		.container {
			padding: 0;
			
			.inner {
				padding-left: percentage(1 / @grid-wide-cols);
				padding-right: @fs;
				
				@media @vp-max-tablet-wide {
					padding-left: @fs;
				}
			}
		}
	}
}
