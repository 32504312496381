ul.archive-list {
  padding:0;
  list-style-type: none;
  margin: @fs 0;
  
  li {
    margin-bottom: @fs/4;
  }
  
  .lighten {
    color: @grey;
  }
}