
/**
 * Wave styles
 */
.wave article {
	-webkit-transition: all 666ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
	   -moz-transition: all 666ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
	    -ms-transition: all 666ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
	     -o-transition: all 666ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
	        transition: all 666ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
}
	.wave article.past {
		-webkit-transform: translateX( -100% );
		   -moz-transform: translateX( -100% );
		    -ms-transform: translateX( -100% );
		     -o-transform: translateX( -100% );
		        transform: translateX( -100% );
	}
	.wave article.future {
		-webkit-transform: translateX( 100% );
		   -moz-transform: translateX( 100% );
		    -ms-transform: translateX( 100% );
		     -o-transform: translateX( 100% );
		        transform: translateX( 100% );
	}


/**
 * Fan styles
 */
.fan article {
	-webkit-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	   -moz-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	    -ms-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	     -o-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	        transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);

	-webkit-transform-origin: 0% 0%;
	   -moz-transform-origin: 0% 0%;
	    -ms-transform-origin: 0% 0%;
	     -o-transform-origin: 0% 0%;
	        transform-origin: 0% 0%;
}
	.fan article.past {
		background: pink;
		-webkit-transform: rotate( -60deg );
		   -moz-transform: rotate( -60deg );
		    -ms-transform: rotate( -60deg );
		     -o-transform: rotate( -60deg );
		        transform: rotate( -60deg );
	}
	.fan article.future {
		background: yellow;
		-webkit-transform: rotate( 70deg );
		   -moz-transform: rotate( 70deg );
		    -ms-transform: rotate( 70deg );
		     -o-transform: rotate( 70deg );
		        transform: rotate( 70deg );
	}


/**
 * Tilt styles
 */
.tilt {
	-webkit-perspective: 800px;
	   -moz-perspective: 800px;
	    -ms-perspective: 800px;
	     -o-perspective: 800px;
	        perspective: 800px;

	-webkit-perspective-origin: 50% 50%;
	   -moz-perspective-origin: 50% 50%;
	    -ms-perspective-origin: 50% 50%;
	     -o-perspective-origin: 50% 50%;
	        perspective-origin: 50% 50%;
}

	.tilt * {
		position: relative;

		-webkit-transition: all 1000ms cubic-bezier(0.260, 0.860, 0.440, 0.985),
							opacity 300ms ease;
		   -moz-transition: all 1000ms cubic-bezier(0.260, 0.860, 0.440, 0.985);
		    -ms-transition: all 1000ms cubic-bezier(0.260, 0.860, 0.440, 0.985),
		    				opacity 300ms ease;
		     -o-transition: all 1000ms cubic-bezier(0.260, 0.860, 0.440, 0.985),
		    				opacity 300ms ease;
		        transition: all 1000ms cubic-bezier(0.260, 0.860, 0.440, 0.985),
		        			opacity 300ms ease;
	}
		.tilt li.past {
			opacity: 0;

			-webkit-transform: translateY( 100% ) translateZ(-200px);
			   -moz-transform: translateY( 100% ) translateZ(-200px);
			    -ms-transform: translateY( 100% ) translateZ(-200px);
			     -o-transform: translateY( 100% ) translateZ(-200px);
			        transform: translateY( 100% ) translateZ(-200px);
		}
		.tilt li.future {
			opacity: 0;

			-webkit-transform: translateY( -100% ) translateZ(-200px);
			   -moz-transform: translateY( -100% ) translateZ(-200px);
			    -ms-transform: translateY( -100% ) translateZ(-200px);
			     -o-transform: translateY( -100% ) translateZ(-200px);
			        transform: translateY( -100% ) translateZ(-200px);
		}


/**
 * Curl styles
 */
.curl {
	-webkit-perspective: 600px;
	   -moz-perspective: 600px;
	    -ms-perspective: 600px;
	     -o-perspective: 600px;
	        perspective: 600px;

	-webkit-perspective-origin: 0% 50%;
	   -moz-perspective-origin: 0% 50%;
	    -ms-perspective-origin: 0% 50%;
	     -o-perspective-origin: 0% 50%;
	        perspective-origin: 0% 50%;
}

	.curl li {
		-webkit-transition: all 600ms ease,
							opacity 200ms ease;
		   -moz-transition: all 600ms ease;
		    -ms-transition: all 600ms ease,
		    				opacity 200ms ease;
		     -o-transition: all 600ms ease,
		    				opacity 200ms ease;
		        transition: all 600ms ease,
		        			opacity 200ms ease;

		-webkit-transform-origin: 0% 0%;
		   -moz-transform-origin: 0% 0%;
		    -ms-transform-origin: 0% 0%;
		     -o-transform-origin: 0% 0%;
		        transform-origin: 0% 0%;

		-webkit-backface-visibility: hidden;
		   -moz-backface-visibility: hidden;
		    -ms-backface-visibility: hidden;
		     -o-backface-visibility: hidden;
		        backface-visibility: hidden;
	}
		.curl li.past {
			opacity: 0;

			-webkit-transform: rotateY( 90deg );
			   -moz-transform: rotateY( 90deg );
			    -ms-transform: rotateY( 90deg );
			     -o-transform: rotateY( 90deg );
			        transform: rotateY( 90deg );
		}
		.curl li.future {
			opacity: 0;

			-webkit-transform: rotateY( 90deg );
			   -moz-transform: rotateY( 90deg );
			    -ms-transform: rotateY( 90deg );
			     -o-transform: rotateY( 90deg );
			        transform: rotateY( 90deg );
		}


.papercut {
	-webkit-perspective: 600px;
	   -moz-perspective: 600px;
	    -ms-perspective: 600px;
	     -o-perspective: 600px;
	        perspective: 600px;

	-webkit-perspective-origin: 0% 0%;
	   -moz-perspective-origin: 0% 0%;
	    -ms-perspective-origin: 0% 0%;
	     -o-perspective-origin: 0% 0%;
	        perspective-origin: 0% 0%;
}
	.papercut li {
		-webkit-transition: all 600ms ease;
		   -moz-transition: all 600ms ease;
		    -ms-transition: all 600ms ease;
		     -o-transition: all 600ms ease;
		        transition: all 600ms ease;

		-webkit-transform-origin: 0% 0%;
		   -moz-transform-origin: 0% 0%;
		    -ms-transform-origin: 0% 0%;
		     -o-transform-origin: 0% 0%;
		        transform-origin: 0% 0%;
	}
		.papercut li.past {
			-webkit-transform: skewY( -30deg );
			   -moz-transform: skewY( -30deg );
			    -ms-transform: skewY( -30deg );
			     -o-transform: skewY( -30deg );
			        transform: skewY( -30deg );
		}
		.papercut li.future {
			-webkit-transform: skewY( 30deg );
			   -moz-transform: skewY( 30deg );
			    -ms-transform: skewY( 30deg );
			     -o-transform: skewY( 30deg );
			        transform: skewY( 30deg );
		}


/**
 * Zipper styles
 */
.zipper li {
	-webkit-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	   -moz-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	    -ms-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	     -o-transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
	        transition: all 600ms cubic-bezier(0.390, 0.575, 0.565, 1.000);

	-webkit-transform-origin: 50% 0%;
	   -moz-transform-origin: 50% 0%;
	    -ms-transform-origin: 50% 0%;
	     -o-transform-origin: 50% 0%;
	        transform-origin: 50% 0%;
}
	.zipper li.past:nth-child(odd),
	.zipper li.future:nth-child(odd) {
		-webkit-transform: translateX( 80% );
		   -moz-transform: translateX( 80% );
		    -ms-transform: translateX( 80% );
		     -o-transform: translateX( 80% );
		        transform: translateX( 80% );
	}
	.zipper li.past:nth-child(even),
	.zipper li.future:nth-child(even) {
		-webkit-transform: translateX( -80% );
		   -moz-transform: translateX( -80% );
		    -ms-transform: translateX( -80% );
		     -o-transform: translateX( -80% );
		        transform: translateX( -80% );
	}


/**
 * Fade styles
 */
.fade li {
	-webkit-transition: opacity .35s ease-in-out;
	   -moz-transition: opacity .35s ease-in-out;
	    -ms-transition: opacity .35s ease-in-out;
	     -o-transition: opacity .35s ease-in-out;
	        transition: opacity .35s ease-in-out;
}
	.fade li.past {
        opacity: 0;
	}
	.fade li.future {
        opacity: 0;
	}


/**
 * Twirl styles
 */
.twirl {
	-webkit-perspective: 400px;
	   -moz-perspective: 400px;
	    -ms-perspective: 400px;
	     -o-perspective: 400px;
	        perspective: 400px;

	-webkit-perspective-origin: 50% 50%;
	   -moz-perspective-origin: 50% 50%;
	    -ms-perspective-origin: 50% 50%;
	     -o-perspective-origin: 50% 50%;
	        perspective-origin: 50% 50%;
}

	.twirl li {
		-webkit-transition: all 600ms ease,
							opacity 200ms ease;
		   -moz-transition: all 600ms ease;
		    -ms-transition: all 600ms ease,
		    				opacity 200ms ease;
		     -o-transition: all 600ms ease,
		    				opacity 200ms ease;
		        transition: all 600ms ease,
		        			opacity 200ms ease;

		-webkit-transform-origin: 50% 50%;
		   -moz-transform-origin: 50% 50%;
		    -ms-transform-origin: 50% 50%;
		     -o-transform-origin: 50% 50%;
		        transform-origin: 50% 50%;
	}
		.twirl li.past {
			opacity: 0;

			-webkit-transform: rotate3d( 80,-70,10,180deg );
			   -moz-transform: rotate3d( 80,70,10,180deg );
			    -ms-transform: rotate3d( 80,70,10,180deg );
			     -o-transform: rotate3d( 80,70,10,180deg );
			        transform: rotate3d( 80,70,10,180deg );
		}
		.twirl li.future {
			opacity: 0;

			-webkit-transform: rotate3d( 80,70,10,-180deg );
			   -moz-transform: rotate3d( 80,70,10,-180deg );
			    -ms-transform: rotate3d( 80,70,10,-180deg );
			     -o-transform: rotate3d( 80,70,10,-180deg );
			        transform: rotate3d( 80,70,10,-180deg );
		}
