footer.global {
	background: @lightest-grey;
	color: @deep-grey;
	padding: @fs*2 0;
	font-family: @font-sans;
	font-weight: 500;
	font-size: @fs-sans;

	@media @vp-max-tablet {
		padding: (@fs * 1.25) 0 0;
	}

	.ds-book-icon {
		width: 30px;
		height: auto;
  		line-height: 1;
		display: inline-block;
		vertical-align: middle;
		margin-right: 0.5em;
		float: right;
		
		@media @vp-max-tablet {
			width: 36px;
			margin-top: 4px;
		}		
		
		@media @vp-min-tablet {
			position: absolute;
			left: 50%;
			margin: 0;
			transform: translateX(-50%);
		}
	}
  
  .inner {
    width: 100%;;
    margin: 0 auto;
    .cf();
		
	@media (max-width: 800px) {
		font-size: @fs-detail; 
	}
	
	@media @vp-max-tablet {
		width: 100%;;
		font-size: @fs-mobile;
	}
  }
	
  p {
  
  	&.copyright-text {
  		float: none;
		display: inline-block;
		margin: 0;

		@media @vp-min-tablet {
			margin: 5px 0;
		}
  	}
  }
  
  form {
    float: left;
		
		@media @vp-max-tablet {
			width: 100%;
		}
		
		@media @vp-phone {
			max-width: 240px;
		}

    input {
      color: @deep-grey;
      outline: none;
	  	float: left;
    }
    
    input[type="email"] {
      background-color: @lightest-grey;
      border: 0;
      border-bottom: 2px solid @light-grey;
		  -webkit-appearance: none;
		  border-radius: 0;
		  padding-left: 0;
		  margin-right: 8px;
		
			@media @vp-max-tablet {
				width: 80%;
			}
			
			@media @vp-phone {
				width: 70%;
			}
			
    }
    
    input[type="submit"] {
      -webkit-appearance: none;
      border: 0;
	  	cursor: pointer;
      background-color: @light-grey;
      padding: 2px 10px;
			float: left;
    }
  }
	
  .col-2 {
    width: 50%;
    float: left;
		
    @media @vp-max-tablet {
      width: auto;
      float: none;
    }
  }
}

.email-signup {	
	@media (max-width: 800px) {
		// padding-top: 5px;
	}

	@media @vp-max-tablet {
		width: 80%;
		margin-right: 0;
	}
}

.email-signup-trigger,
.email-signup-form,
.email-hint,
.rss-link {
	float: right;

  @media @vp-max-tablet {
		float: left;
	}

	@media @vp-min-tablet {
	  margin: 5px 0;
	}
}

.email-signup-trigger {
	cursor: pointer;
	box-shadow: inset 0 -2px 0 0 @light-grey;
}

.email-signup-form {
	display: none;
	
	.email-signup-form-container & {
		display: block;

	}
}

.rss-link {
	.rss-icon {
		display: inline-block;	
		margin: 0 @fs;
		box-shadow: inset 0 -2px 0 0 @light-grey;
		box-sizing: content-box;
		padding: 5px 0;
		width: auto;
		height: @fs;
		
		@media (max-width: 800px) {
			height: 16px;
			padding-bottom: 4px;
		}
		
		@media @vp-max-tablet {
			height: 16px;
			padding-bottom: 7px;
		}
		
		@media @vp-phone {
			height: 18px;
			margin: 5px 18px;
			padding-bottom: 5px;
		}
	}
}

.rss-icon,
.email-signup-trigger {
	@media @vp-phone {
		padding: 5px 0 0;		
	}
}

// This specifies styles for the stand alone sign up page
.email-signup-form-container {
	width: 100%;
	display: inline-block;
	
	.email-signup-form {
		width: 100%;
	}
	
	form {
		width: 100%;
	}
	
	input[type="email"] {
		border: none;
		float: left;
		background: @lightest-grey;
		padding: 10px 14px;
		width: 50%;
		font-family: @font-sans;
		
		.placeholder( fade(@text, 66%) );
		
		// iOS rules
		-webkit-appearance: none;
		border-radius: 0;
		
		@media @vp-max-tablet{
			width: 75%;
		}
		
		&:focus {
			outline: none;
		}
	}
	
	input[type="submit"] {
		.inline-button();
		font-size: @fs;
		padding: 10px 14px;
		border-radius: 0;

		
		@media @vp-max-tablet{
			font-size: 18px;
			padding: 10px 14px;
		}
	}
}

.email-signup {
	min-height: 60px;	
	
	@media @vp-max-tablet {
		margin-top: 5px;
	}
}