header {
	background-color: @yellow;
	
	.title {
		display: none;
		z-index: 3;
	}
	
	.logo-link {
		display: inline-block;
		width: 100%;

		@media @vp-max-tablet {
			display: block;
			width: 70%;
			margin: 0 auto;
		}
	}

	.featured-image {
		margin: 0 auto;
		display: block;
		
		@media @vp-min-tablet {
			width: 60%;
			padding: @fs 0;
		}
		
		@media @vp-max-tablet {
			width: 100%;
			padding-top: @fs*1.5;
		}
	}
	
	.secondary {
		display: inline;
		
		@media @vp-mobile-nav-active {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			height: 0; // Initial height
			background-color: fade(@yellow, 0%);
			padding: @fs;
			transition+: height 0.25s @easeOutExpo 0.75s; // Delay for close
			transition+: background-color 1s @easeOutExpo 0s;
			display: flex;
			flex-direction: column;
			justify-content: center;
			
			.internal { float: none; }
			
			> a {
				margin: 0 !important;
				
				&.internal {
					padding: (@fs/2) @fs;
					width: 75%; /* Allow for close button */
				}
			}
		}
		
		.social-links {
			display: inline-block;
			font-size: 0;
			position: relative;
			top: 2px;
			padding-left: 0;
			
			@media @vp-mobile-nav-active {
				padding: (@fs/2) @fs (@fs/2) 15px;
			}

			> .service {
				display: inline-block;
				
				a {
					position: relative;
					margin-right: 0;
				}
				
				&.-facebook {
					a { top: 1px; }
				}
				
				+ .service {
					margin-left: 15px;
					
					&.-tumblr { margin-left: 12px; }
				}
			}
		}
		
		@media (max-width: 900px) {
			padding-right: @fs;
		}
		
		.internal,
		.social-links {
			@media @vp-mobile-nav-active {
				z-index: -1;
				visibility: hidden;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
				opacity: 0;
				transform: translateY(-5rem);
				transition+: transform 0.75s @easeOutExpo 0.25s;
				transition+: opacity 0.5s @easeOutExpo 0s;
				transition+: visibility 0s @easeOutExpo 0.75s;
			}
		}
		//
		// @media @vp-max-tablet {
		// 	display: none;
		// 	padding-right: 0;
		// }
	}
	
	&.full {
		z-index: 20;
		position: relative;
		
		.secondary {
			.internal {
				float: left;
			}
			
			// @media @vp-max-tablet {
			// 	background: @yellow;
			// 	border-bottom: 0;
			// 	top: 0;
			// 	opacity: 1;
			// }
		}
		
		// .mobile-nav-open {
		// 	.secondary {
		// 		@media @vp-max-tablet {
		// 			opacity: 1;
		// 		}
		// 	}
		// }
	}
	
	&.compressed {
		z-index: 10;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		.transform(translateY(-100%));
		.transition(0.2s transform ease); 
		
		&.visible {
			.transform(translateY(0));
		}
		
		.featured-image, h4 {
			display: none;
		}
		
		.title {
			display: block;
		}
		
		.secondary {
			display: inline;
			font-size: 16px;
			
			@media (max-width: 900px) {
				padding-right: @fs;
			}
			
			.extended {
				@media @vp-min-tablet and @vp-max-tablet-wide {
					display: none;
				}
			}
			
			@media @vp-max-tablet {
				display: none;
				padding-right: 0;
			}
		}

		.main-site {
			a { margin-right: 0; }

			@media @vp-phone {
				display: none;
			}
		}
	}
	
	nav {
		> .menuburger {
			color: currentColor;

			.menu-hamburger(
				@target-width: 44px;
				@target-height: 44px;
				@burger-width: 30px;
				@burger-height: 8px;
				@bun-thickness: 2px;
				@include-patty: false;
			);

			@media @vp-mobile-nav-inactive {
				display: none;
			}
		}

		@media @vp-mobile-nav-active {
			text-align: left;
			
			> .menuburger {
				position: fixed;
				z-index: @zindex-menu-burger;
				top: 4px;
				right: 13px;
				touch-action: manipulation;
			}

			> .menuburger {
				> .ingredient > .bar {
					// Fatten up the strokes
					box-shadow: inset 0 0 1px currentColor;
				}

				background-color: transparent !important;
				.prevent-user-select();
			}
		}
	}
}

#main-nav-menustate:checked ~ header {
	@media @vp-mobile-nav-active {
		nav {
			.secondary {
				height: 100%;
				transition+: height 0.25s @easeInOut 0s;
				transition+: background-color 1s @easeOutExpo 0.25s;
				background-color: fade(@yellow, 100%);
			
				> .internal,
				> .social-links {
					z-index: @zindex-main-nav-items;
					visibility: visible;
					opacity: 1;
					transform: translateY(0);
					transition+: transform 0.5s @easeOutExpo unit(1 / 3, s);
					transition+: opacity 0.25s @easeOutExpo unit(1 / 3, s);
					transition+: visibility 0s @easeOutExpo 0s;
				}
			}

			> .menuburger {
				.menu-hamburger-open();
			}
		}
	}
}