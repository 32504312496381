.body-text {
	&.-medium {
		font-size: @fs-medium;
		line-height: 30 / 18;
	}
	
	&.-links a {
	    box-shadow: inset 0 -2px 0 0 @link;
	    transition: 0.6s all ease-out;
	    cursor: pointer;
	    
	    &:hover {
	      box-shadow: inset 0 -2px 0 0 @transparent;
	      transition-duration: 0.2s;
	      // color: @deep-grey;
	    }
	}
}