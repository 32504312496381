.font-face();

body {
  font-size: @fs;
  font-family: @font-primary;
  line-height: @base-line-height;
  .subpixel(false);
  
  @media @vp-max-tablet {
    font-size: @fs-mobile;
    line-height: @base-line-height-mobile;
  }
}

::selection {
  text-shadow: none;
  background: fade(@yellow, 66%);
}

a {
  text-decoration: none;
  color: inherit;
}

nav {
    padding: @fs*1.1 0 @fs;
    line-height: 1.33;
    text-align: right;
    font-size: @fs-sans;
    font-family: @font-sans;
	font-weight: 500;
    
    @media @vp-max-tablet {
      padding: (@fs*0.75) 0;
    }
    
    .inner {
      width: 100%;
    }
    
    a {
      color: @text;
      display: inline-block;
      margin-right: @fs;
      position: relative;
      
      &.title {
         float: left;
         position: relative;
         top: -2px;
         
         img {
           height: 35px;
           width: auto;
         }
      }
    }
}

h1 {
  font-size: @fs*1.75;
  font-family: @font-display;
  line-height: @base-line-height-large;
  font-weight: 400;
  margin: 0;
  
  @media @vp-max-tablet {
    font-size: @fs*1.25;
  }
}

h3 {
	&.filter {
		font-family: @font-sans;
		font-weight: 500;
	    font-size: @fs-sans-large;
		color: @deep-grey;
	    line-height: 1.4;
	    margin: (@fs*3) 0;

		@media @vp-max-tablet {
			margin: (@fs*2) 0;
		}
	}
}

h4 {
  font-family: @font-sans;
  font-size: @fs-sans;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: @fs 0;
  line-height: 1;
}

article {
  width: 100%;
  margin: (@fs-large-featured) auto (@fs-large-featured * 1.5);
  
  @media @vp-max-tablet {
    width: 100%;;
    margin: (@fs) auto (@fs * 1.5);
  }
  
	img {
		display: block;
	}
	
	> img { margin: @fs 0; }
  
  &.story {
    .inner-story {
      padding: @fs 0;

      p {
        margin: 0;
        text-indent: @lh;
		
		img {
			margin: @fs 0;
		}
        
        &:first-of-type {
			text-indent: 0;
		}

		@media @vp-max-tablet {
			text-indent: @lh / 2;
			
			&:first-of-type {
				text-indent: 0;
			}
		}
      }
    }
  }
  
  &.news-preview {
    font-family: @font-sans;
    font-weight: 500;
    padding: 0 0 0 @fs;
    border-left: 2px solid @yellow;
    line-height: 1.4;
    
    @media @vp-max-tablet {
      margin-left: -@fs;
      border-left: 5px solid @yellow;
    }
    
    .news-preview-body {
      display: inline;
      margin-right: @fs/2;
    }
    
    a.read-more {
      .inline-button();
    }
    
    h3 {
      font-family: @font-sans;
	  font-weight: 600;
      font-size: @fs;

	    @media @vp-max-tablet {
	      font-size: @fs-mobile;
	    }
    }
    
    p {
      font-weight: 500;
      margin-bottom: 0;

	    @media @vp-max-tablet {
	      font-size: @fs-sans-mobile;
	    }
    }
  }
  
  h3 {
    font-weight: 700;
    font-size: @fs-story-title;
    font-family: @font-serif;
    margin: 0;
    line-height: 1.25;
    
    @media @vp-max-tablet {
      font-size: @fs-story-title-mobile;
    }
  }
  
  u,
  .underline,
  p a,
  li a {
    font-weight: 400;
    color: inherit;
    text-decoration: none;
  }
  
  // .highlight is used in older tumblr posts
  u, .highlight, .underline {
    box-shadow: inset 0 -2px 0 0 @dictionary-word-color;
  }  
  
  p a,
  li a {
    box-shadow: inset 0 -2px 0 0 @link;
    transition: 0.6s all ease-out;
    cursor: pointer;
    
    &:hover {
      box-shadow: inset 0 -2px 0 0 @transparent;
      transition-duration: 0.2s;
    }
  }

  blockquote {
    font-size: @fs-blockquote;
    line-height: @base-line-height-large;
    font-family: @font-display;
    margin: 0 0 @fs;
    
    @media @vp-max-tablet {
      font-size: @fs-blockquote-mobile;
      line-height: 1.35;
    }
    
    u, .underline {
      background-image: linear-gradient(to top,
      rgba(0,0,0,0) 4%,
      @dictionary-word-color 4%,
      @dictionary-word-color 8%,
      rgba(0,0,0,0) 8%
      );
      box-shadow: none;
    }
  }
  
	> iframe {
		margin: @fs 0;
	}
  
	.fluid-width-video-wrapper {
		margin: @fs 0;
	
		> iframe {
			margin: 0;
		}
	}
  
  footer {
    .metadata-text();
    .cf();
    
    // When no tags, make Dictionary Column full width
    // to avoid text wrapping
    &[data-tags="false"] {
      .-first,
	  .col.-first {
        width: 100%;
      }
    }
    
    a {
      color: inherit;
      border-bottom: 1px solid @transparent;
      box-shadow: none;
      
      &:hover {
        border-bottom: 1px solid @light-grey;
      }
    }
    
    .row {
      width: 100%;
      display: inline-block;
      
      .col {
        padding-right: @fs;
        
        @media @vp-max-tablet {
          margin-bottom: 2px;        
        }
      }
    }
    
    .col {
      float: left;
      
      &.-first {
        width: 60%;
        
        body.tumblr & {
          width: 100%;
        }
      }
      
      &.-tags {
        width: 40%;
      }
      
      @media @vp-max-tablet {
        &.-first,
        &.-tags {
          width: 100%;
        }
      }
      
      img {
      	display: inline-block;
    	position: relative;
    	top: 4px;
    	margin: 0 4px 0 0;
        float: left;
        
        @media @vp-max-tablet {
          top: 4px;
        }
      }
      
      ul {
        padding-left: 24px;
        line-height: 24px;
        margin: 0;
        list-style: none;
        
        li {
          display: inline;
          
          &:after {
            content: ', ';          
          }
          
          &:last-child:after {
            content: none;
          }

        }
      }
    }
    
    @media @vp-max-tablet {
      font-size: @fs-mobile-detail;
    }
    
    p {
      margin: 0;
      letter-spacing: 0!important;
      word-spacing: 0!important;
    }
  }
}