// Quote-specific mixins
.featured-quote-layout() {
	// Based on location within an 8 column container (wide grid)
	margin-left: -(percentage(1 / 8));
	width: percentage(9 / 8 );
	padding-left: percentage(1 / 8);
	position: relative;
	margin-bottom: 2em;
	
	p {
		@media @vp-min-tablet {
			#gutters > .as-padding(8);
		}
	}
	
	.embelishment {
		position: absolute;
		width: percentage( 1 / 9 );
		left: 0;
		top: 0;
		bottom: 0;
		
		&:before {
			// Line
			left: 50%;
			margin-left: -2px;
			width: 4px;
			height: 100%;
		}

		&:after {
			// Quote symbol
			position: absolute;
			left: 50%;
			top: 2em;
			margin-left: -27px;
		}
	}
}

.regular-quote-layout() {
	// Based on location within an 6 column container (wide grid)
	position: relative;
	margin-top: 3em;
	
	p {
		text-align: center;
		
		@media @vp-min-tablet {
			#gutters > .as-padding(6);
		}
	}
	
	.embelishment {
		width: percentage( 4 / 6 );
		margin: 0 auto;
		
		+ p { margin-top: 0; }
		
		&:before {
			// Line
			z-index: 0;
			left: unit(@grid-wide-gutter, px);
			top: 50%;
			right: unit(@grid-wide-gutter, px);
			margin-top: -2px;
			height: 4px;
		}

		&:after {
			// Quote symbol
			position: relative;
			z-index: 1;
			margin: 0 auto;
		}
	}	
}

.book-quote {
	position: relative;
	margin: 0;
	
	@media @vp-tablet-wide {
		font-size: @fs-medium;
		line-height: 30 / 18;
	}
	
	.citation {
		display: block;
		.metadata-text();
		
		@media @vp-tablet-wide {
			font-size: @fs-mobile-detail;
		}
		
		@media @vp-max-small {
			font-size: 14px;
		}
	}
	
	.embelishment {
		position: relative;
		
		&:before {
			// Line
			content: "";
			position: absolute;
			display: block;
			background: @yellow;
		}

		&:after {
			// Quote symbol
			content: "";
			width: 54px;
			height: 50px;
			display: block;
			background-color: white;
			background-image: url("../img/quote.svg");
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
	}
	
	&.-regular {
		.regular-quote-layout();
	}
	
	&.-featured {
		@media @vp-min-tablet {
			.featured-quote-layout();
		}
		
		@media @vp-max-tablet {
			.regular-quote-layout();
		}
	}
}

.press-quotes {
	.book-quote {
		font-family: @font-serif;
		font-size: inherit;
		font-weight: normal;
		line-height: inherit;
		
		@media @vp-max-tablet {
			&:first-child { margin-top: 1em; }
		}
		
		@media @vp-min-tablet {
			.embelishment {
				&:before {
					// Line
					left: auto;
					right: 12px;
					margin-left: 0;
					width: 2px;
				}

				&:after {
					// Quote symbol
					left: auto;
					right: 0;
					top: 0;
					margin-left: 0;
					width: 27px;
					height: 25px;
				}
			}
		}
		
		@media @vp-min-tablet and (max-width: 840px) {
			margin-left: 0;
			width: 100%;
			box-sizing: border-box;
			padding-left: 27px;
			
			.embelishment { width: 27px; }
		}
	}
}