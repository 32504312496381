.book-cover {
	margin: 0;
	#gutters > .as-padding(8);
	
	&.-desktop {
		@media @vp-max-tablet {
			display: none;
		}
	}
	
	&.-mobile {
		@media @vp-min-tablet {
			display: none;
		}
	}
	
	.image {
		position: relative;
		
		@media @vp-max-tablet {
			width: 80%;
			margin: 0 auto;
		}
		
		img {
			display: block;
			position: relative;
			z-index: 1;
			width: 100%;
			height: auto;
			box-shadow: 0 1px 2px fade(@black, 35%);
		}
		
		&:before {
			position: absolute;
			z-index: 0;
			content: "";
			display: block;
			bottom: -10px;
			height: 24px;
			background: url("../img/book-shadow.png") no-repeat;
			background-size: 100% 100%;
			left: -(unit(@grid-wide-gutter * 4, px));
			right: -(unit(@grid-wide-gutter * 4, px));
			
			@media @vp-max-small {
				height: 20px;
				bottom: -7px;
				left: -(unit(@grid-wide-gutter * 3, px));
				right: -(unit(@grid-wide-gutter * 3, px));
			}
		}
	}
	
	.caption {
		.metadata-text();
		line-height: 24 / 16;
		text-align: center;
		margin-top: 2em;
		
		@media @vp-tablet-wide {
			font-size: @fs-mobile-detail;
		}
		
		@media @vp-max-small {
			font-size: 14px;
		}
		
		p { margin: 0; }
	}
}