.site-grid {
	display: flex;
	z-index: 9999;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 100%; // IE10
	max-width: unit(@grid-wide-width, px);
	margin: 0 auto;
	pointer-events: none;
	opacity: 0.05;
	
	// @media @m-max-medium {
		// padding-left: @fs;
		// padding-right: @fs;
	// }
	
	.column {
		flex: 1;
		background-color: #f00;
		// .col-gutters-as-margins;
		margin-left: percentage(@grid-wide-gutter / @grid-wide-width);
		margin-right: percentage(@grid-wide-gutter / @grid-wide-width);
	}
}