.menu-button-wrapper {
  background: @yellow;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 2;

  @media @vp-min-tablet {
    display: none;
  } 
}