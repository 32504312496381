.spin span,
.fright span,
.grow,
.jump {
	position: relative;
	display: inline-block;
	.transition(150ms all ease);
	transform-origin: 50% 50%;
}

.spin:hover span {
	.transform( rotate(-15deg) );
}

.spin.reverse:hover span {
	.transform( rotate(15deg) );
}

.spin:active span {
	.transform( rotate(0deg) );
}

.fright:hover span {
	&:nth-child(odd) {
		.transform( translateY(-2px) );
	}
	&:nth-child(even) {
		.transform( translateY(1px) );
	}
}

.fright:active span {
	&:nth-child(odd) {
		.transform( translateY(0) );
	}
	&:nth-child(even) {
		.transform( translateY(0) );
	}
}

.grow {
	transform: scale(1);
	
	&:hover {
		.transform(scale(1.1));
	}
}

.jump {
	.transform(translateY(0));
	
	&:hover {
		.transform(translateY(-2px));
	} 
	
	&:active {
		.transform(translateY(0px));
	} 
}