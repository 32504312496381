.stockist-countries {
	> .tabs {
		text-align: center;
	    padding: 0;
		
		> .tab {
			.button-reset;
			display: inline-block;
			padding: 0.15em 0.5em;
			margin: 0 0.1em;
			position: relative;
			opacity: 0.5;
			transition: all 0.2s ease-out;
			border-radius: 3px;
			background: transparent;
			
			&:active,
			&:focus { outline: none; }
			
			&:hover {
				opacity: 1;
				background: fade(@black, 10%);
			}
			
			&.-selected {
				opacity: 1;
				background: @black;
				color: white;
				
				&:after {
					content: "";
					display: block;
					width: 0;
					height: 0;
					border: 6px solid transparent;
					border-top-color: @black;
					position: absolute;
					top: 100%;
					left: 50%;
					transform: translateX(-50%); 
				}
			}
		}
	}
	
	> .stockist-list {
		display: none;
		
		&.-selected {
			display: block;
		}
	}
}