* {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	height: auto;
}

body {
	color: @text;
	background-color: @lightest-grey;

	&.tumblr .tmblr-full {
		margin-left: 0;
		margin-right: 0;

		img {
			width: 100%;
			margin-top: 0;
		}
	}
}

.tagline, .main-site a {
	font-size: @fs-sans - 1;
	line-height: 1.3;
	
	@media @vp-max-tablet {
		font-size: @fs-sans-mobile;
	}
	
	@media (max-width: 340px) {
		font-size: 13px;
	}
	
	&.-large {
		@media @vp-min-grid-wide {
			font-size: @fs-story-title;
			line-height: 30 / 23;
		}
		
		@media @vp-max-grid-narrow {
			padding: 0 0 15px;
		}
	}
}

main {
	padding: @fs 0;
	background: @white;
}

.container { 
	max-width: 770px;
	margin: 0 auto;
	padding: 0 @fs;

	nav.centred {
		text-align: center;
		
		a { margin-right: 0; }
	}
}

nav ol {
	list-style-type: none;
	/* float: left; */
	margin-top: @fs*2;
	padding: 0;
	display: block;
	text-align: left;
	color: @grey;
	font-family: @font-sans;
	
	@media @vp-max-tablet {
		.push(0);
	}
	
	li {
		display: inline-block;
		
		&:first-child > * {
			padding-left: 0;
		}
		
		&.current > * {
			color: @light-grey;
			.transform( translateY(-0px) )!important; 
		}
		
		> * {
			color: @deep-grey;
			margin: 0px;
			padding: 10px;
		}
	}
}

.underline-note {
	background:@yellow;
	font-family: @font-sans;
	margin: 0;
	padding: 8px 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	border-bottom: @fs solid @yellow; // To compensate for bounce effect

	.transform(translateY(100%));	
	.transition( 350ms all @easeOutBounce );
	
	@media @vp-max-tablet {
		line-height: 1.3;
	}
	
	&.showing {
		.transform(translateY(@fs));  // To compensate for bounce effect
	}
	
	 p {
		 margin: 0;
	 }
	 
	 img {
		 width: 28px;
		 float: left;
		 margin-top: 3px;
		 margin-right: 12px;
		 margin-left: -48px;
	 }
	 strong {
		 font-weight: 400;
     background-image: linear-gradient(to top,
     @transparent 0%,
     @yellow 0%,
     @yellow 8%,
     @transparent 8%
     );
	 }
}

// Tumblr Notes
ol.notes {
	list-style: none;
	padding: 0;
	margin-top: 2em;
	border-bottom: 1px solid @border-grey;

	li.note {
		color: @deep-grey;
		font-family: @font-sans;
		font-size: @fs-detail;
		border-top: 1px solid @border-grey;
		padding: 0.5em 0 0.7em;

		blockquote {
			font-family: @font-sans;
			font-size: @fs-detail;
			font-style: italic;
			padding-left: 1em;
			margin: 0.5em 0 0;
			
			a { box-shadow: none; }
		}
	}
	
	.avatar_frame {
		display: none;
	}
}
