.press-links {
	list-style: none;
	padding-left: 0;
	
	> li {
		display: inline;
		
		&:after {
			content: ", ";
			display: inline;
		}
		
		&:last-of-type {
			&:after { display: none; }
		}
	}
}