.font-face(@root: '../') {
	@font-face{ 
		font-family: 'TiemposTextWeb';
		src: url('@{root}fonts/TiemposTextWeb-Regular.eot');
		src: url('@{root}fonts/TiemposTextWeb-Regular.eot?#iefix') format('embedded-opentype'),
			url('@{root}fonts/TiemposTextWeb-Regular.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face{ 
		font-family: 'TiemposTextWeb';
		src: url('@{root}fonts/TiemposTextWeb-RegularItalic.eot');
		src: url('@{root}fonts/TiemposTextWeb-RegularItalic.eot?#iefix') format('embedded-opentype'),
			url('@{root}fonts/TiemposTextWeb-RegularItalic.woff') format('woff');
		font-weight: normal;
		font-style: italic;
	}

	@font-face{ 
		font-family: 'TiemposTextWeb';
		src: url('@{root}fonts/TiemposTextWeb-Semibold.eot');
		src: url('@{root}fonts/TiemposTextWeb-Semibold.eot?#iefix') format('embedded-opentype'),
			url('@{root}fonts/TiemposTextWeb-Semibold.woff') format('woff');
		font-weight: bold;
		font-style: normal;
	}

	@font-face{ 
		font-family: 'TiemposTextWeb';
		src: url('@{root}fonts/TiemposTextWeb-SemiboldItalic.eot');
		src: url('@{root}fonts/TiemposTextWeb-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
			url('@{root}fonts/TiemposTextWeb-SemiboldItalic.woff') format('woff');
		font-weight: bold;
		font-style: italic;
	}

	@font-face{ 
		font-family: 'TiemposHeadlineWeb-Semibold';
		src: url('@{root}fonts/TiemposHeadlineWeb-Semibold.eot');
		src: url('@{root}fonts/TiemposHeadlineWeb-Semibold.eot?#iefix') format('embedded-opentype'),
			url('@{root}fonts/TiemposHeadlineWeb-Semibold.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face{ 
		font-family: 'TiemposHeadlineWeb-Semibold';
		src: url('@{root}fonts/TiemposHeadlineWeb-SemiboldItalic.eot');
		src: url('@{root}fonts/TiemposHeadlineWeb-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
			url('@{root}fonts/TiemposHeadlineWeb-SemiboldItalic.woff') format('woff');
		font-weight: normal;
		font-style: italic;
	}
}