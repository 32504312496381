.inline-button {
  display: inline-block;
  margin: 0;
  padding: 2px 6px 0;
  text-transform: uppercase;
  font-size: @fs-detail - 1;
  letter-spacing: 1px;
  background: @yellow;
  box-shadow: none;
  text-decoration: none;
  color: @text;
  border: none;
  font-family: @font-sans;
  cursor: pointer;

  &:hover, &:active {
    background: @text;
    color: @white;
  }
  
  @media @vp-max-small {
    font-size: @fs-mobile-detail - 1;
    padding: 2px 4px 0;
  } 
}

.control-reset() {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	border-radius: 0;
	background: transparent;
	background-clip: border-box;
}

.button-reset() {
	.control-reset();
	display: inline-block;
	font-family: inherit;
	appearance: none;	
	text-decoration: none;
	cursor: pointer;

	&:focus,
	&:hover {
		text-decoration: inherit;
	}
}