#gutters {
	.as-padding(@parent-col-count: @grid-wide-cols) {
		padding-left: unit(@grid-wide-gutter, px);
		padding-right: unit(@grid-wide-gutter, px);
		
		& when (@parent-col-count = 0) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	.as-margin(@parent-col-count: @grid-wide-cols) {
		margin-left: unit(@grid-wide-gutter, px);
		margin-right: unit(@grid-wide-gutter, px);

		& when (@parent-col-count = 0) {
			margin-left: 0;
			margin-right: 0;
		}
	}	
}